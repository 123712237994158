/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-12-30",
    versionChannel: "nightly",
    buildDate: "2023-12-30T00:15:06.603Z",
    commitHash: "583327ff6255913bdc48ae93fb78d58016c8f763",
};
